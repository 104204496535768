<template>
  <div class="us-container">
    <div
      class="us-swiper"
      :style="'height:' + swiperHeight + 'px'"
    />
    <!-- 关于我们 -->
    <div class="us-about">
      <div class="us-about-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">关于我们</span>
          <span class="title-content-cn">About us</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="us-about-content">
        <div class="about-content-left">
          <img
            src="../assets/Images/about-us-1.jpg"
            alt=""
          >
        </div>
        <div class="about-content-right">
          <p class="right-desc m-g-10">
            劳务邦，是一个劳务行业服务平台。平台致力于为企事业单位提供劳务采购、评估与监管服务；为劳务公司提供孵化与支持服务；为劳务从业人员提供技能培训、就业及相关生活领域的支持服务。
          </p>
          <p class="right-desc m-g-10">
            劳务邦创办于2011年，历经十年，获得了长足的发展。在线下，平台已经孵化邦泰人力、杰博人力、泰盛人力、泰启人力、邦博人力等劳务品牌;在线上，开发了打工宝、兼雇宝、财库通、校差兼职、兼雇保、明薪卡等线上互联网服务产品；为了更好的服务平台会员，纯石咨询、宇泰财税、博达教育等一系列培训教育机构也应运而生。
          </p>
          <p class="right-desc m-g-10">
            十年磨一剑，劳务邦打造了业内“六脉神剑”。建立了品牌策划、人资管理、培训支持、软件系统、商务支持及财税管理六大共享服务中心。建立了华东、华北、华中、华南、西南、西北六大区域管理总部。
          </p>
        </div>
      </div>
      <div class="us-about-content">
        <img style="width: 100%;" src="../assets/Images/map.jpg" alt="">
      </div>
      <div class="us-about-content us-about-desc">
        <p>目前，劳务邦在全国100+城市成立了平台会员机构，与近万家知名企业建立了长久的合作关系，被央视、北京卫视等多家电视新闻媒体宣传报道，获得了“全国人力资源优秀服务商”、“年度杰出人力资源服务机构”、“人力资源服务行业协会副会长单位等荣誉”，得到了社会各界的广泛认可。</p>
        <p>未来，劳务邦还将利用核心人力资源技术，投资于择校、择业、择偶等三大领域的产业发展，并为夫妻、亲子、养老等三大亲密关系保驾护航。</p>
      </div>
    </div>
    <!-- 劳务邦大事件 -->
    <div class="us-event">
      <div class="us-event-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">劳务邦大事件</span>
          <span class="title-content-cn">Timelines</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="us-event-content">
        <div
          v-for="(value, key, index) in lines"
          :key="index"
          class="event"
        >
          <div :class="[index % 2 === 1 ? 'event-left' : 'event-right', 'event-item']">
            <div
              v-if="index % 2 === 0"
              class="event-year"
            >
              {{ key }}
            </div>
            <span class="event-circle" />
            <div
              v-if="index % 2 === 1"
              class="event-year"
            >
              {{ key }}
            </div>
            <div
              :class="[index % 2 === 1 ? 'left' : 'right', 'event-detail']"
              :style="'width: ' + eventWidth + 'px'"
            >
              <span
                v-for="(item, idx) in value"
                :key="idx"
              >{{ item.event_at_show }} {{ item.event }}</span>
            </div>
          </div>
          <p class="event-vertical" />
        </div>
        <span class="event-branch-end">未完待续...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      swiperHeight: 0,
      clientWidth: 0,
      eventWidth: 0,
      // aboutUs: "",
      // branchs: [],
      // 大事件
      lines: {}
    }
  },
  mounted () {
    this.getLines()
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    if (this.clientWidth >= 700) {
      this.eventWidth = 350
    } else {
      this.eventWidth = this.clientWidth / 2 - 40
    }
    window.onresize = () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
      if (this.clientWidth >= 700) {
        this.eventWidth = 350
      } else {
        this.eventWidth = this.clientWidth / 2 - 40
      }
    }
  },
  methods: {
    // 获取大事件数据
    getLines () {
      this.$store.dispatch('getTimeLines').then(res => {
        this.lines = res
      })
    }
  }
}
</script>
<style lang="less">
.us-swiper {
  width: 100%;
  background-image: url("../assets/Images/about-us-swiper.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* 关于我们 */
.us-about {
  .us-about-content {
    display: flex;
    .about-content-left {
      margin-top: 85px;
      img {
        position: relative;
        top: -20px;
        // left: -50px;
        left: -25px;
      }
    }
    .us-about-desc{
      flex-direction: column;
      p {
        letter-spacing: 2px;
        text-align: left;
        text-indent: 2em;
        color: @global-text-color;
      }
    }
    .about-content-right {
      box-sizing: border-box;
      p {
        letter-spacing: 2px;
        text-align: left;
        text-indent: 2em;
        color: @global-text-color;
      }
    }
  }
}
/* 劳务邦大事件 */
.us-event {
  .us-event-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .event {
      position: relative;
    }
    .event-item {
      display: flex;
      align-items: center;
      .event-year {
        color: #2bafe8;
      }
      /* 节点 */
      .event-circle {
        display: block;
        background: #2bafe8;
        border-radius: 50%;
      }
      /* 详情 */
      .event-detail {
        min-height: 90px;
        background-image: url("../assets/Images/about-event-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        top: -40px;
        span {
          display: block;
          text-align: left;
          margin-bottom: 7px;
        }
      }
      .right {
        transform: rotate(180deg);
        align-items: flex-end;
        span {
          transform: rotate(180deg);
        }
      }
    }
    /* 竖线 */
    .event-vertical {
      display: inline-block;
      background: #76dafd;
      margin: 0;
    }
    .event-branch-end {
      color: #2bafe8;
      display: block;
      margin-top: 15px;
      margin-left: 20px;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  /* 关于我们 */
  .us-about-content {
    width: 1200px;
    margin: 20px auto 0;
    .about-content-left {
      display: block;
      border: 2px solid #8d9b9e;
      width: 352px;
      height: 338px;
      img {
        width: 352px;
        height: 338px;
      }
    }
    .about-content-right {
      padding: 60px 40px 5px 60px;
      p {
        margin-bottom: 20px;
        line-height: 33px;
        font-size: 16px;
      }
    }
  }
  .us-about-desc{
    flex-direction: column;
    p {
      letter-spacing: 2px;
      text-align: left;
      text-indent: 2em;
      color: @global-text-color;
      line-height: 33px;
    }
  }
  /* 劳务邦大事件 */
  .us-event-content {
    width: 875px;
    margin: 40px auto 80px;
    .event-year {
      font-size: 30px;
    }
    .event-circle {
      width: 36px;
      height: 36px;
      border: 6px solid #76dafd;
    }
    .event-vertical {
      width: 7px;
      height: 115px;
    }
    .event-left {
      margin-left: 96px;
      .event-year {
        margin-left: 30px;
      }
    }
    .event-right {
      margin-right: 96px;
      .event-year {
        margin-right: 30px;
      }
    }
    .event-detail {
      // width: 350px;
      font-size: 14px;
      padding: 20px 30px;
    }
    .left {
      right: 170px;
    }
    .right {
      left: 170px;
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  /* 关于我们 */
  .us-about-content {
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    box-sizing: border-box;
    .about-content-left {
      display: block;
      border: 2px solid #8d9b9e;
      width: 262px;
      height: 248px;
      img {
        width: 262px;
        height: 248px;
      }
    }
    .about-content-right {
      padding: 10px 20px 0 10px;
      p {
        line-height: 30px;
        font-size: 16px;
      }
    }
  }
  .us-about-desc{
    flex-direction: column;
    p {
      letter-spacing: 2px;
      text-align: left;
      text-indent: 2em;
      color: @global-text-color;
      line-height: 30px;
    }
  }
  /* 劳务邦大事件 */
  .us-event-content {
    width: 100%;
    margin: 40px auto 80px;
    padding: 0 20px;
    box-sizing: border-box;
    .event-year {
      font-size: 20px;
    }
    .event-circle {
      width: 30px;
      height: 30px;
      border: 5px solid #76dafd;
    }
    .event-vertical {
      width: 4px;
      height: 100px;
    }
    .event-left {
      margin-left: 65px;
      .event-year {
        margin-left: 20px;
      }
    }
    .event-right {
      margin-right: 65px;
      .event-year {
        margin-right: 20px;
      }
    }
    .event-detail {
      // width: 350px;
      font-size: 14px;
      padding: 20px 30px;
    }
    .left {
      right: 120px;
    }
    .right {
      left: 120px;
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  /* 关于我们 */
  .us-about-content {
    width: 100%;
    margin: 0 auto;
    padding-left: 10px;
    box-sizing: border-box;
    .about-content-left {
      display: none;
    }
    .about-content-right {
      padding: 0 20px 5px 20px;
      p {
        line-height: 28px;
        font-size: 17px;
      }
    }
  }
  .us-about-desc{
    flex-direction: column;
    p {
      letter-spacing: 2px;
      text-align: left;
      text-indent: 2em;
      color: @global-text-color;
      line-height: 28px;
    }
  }
  /* 劳务邦大事件 */
  .us-event-content {
    width: 100%;
    margin: 40px auto 60px;
    padding: 0 10px;
    box-sizing: border-box;
    .event-year {
      font-size: 20px;
    }
    .event-circle {
      width: 18px;
      height: 18px;
      border: 3px solid #76dafd;
    }
    .event-vertical {
      width: 4px;
      height: 65px;
    }
    .event-left {
      margin-left: 60px;
      .event-year {
        margin-left: 15px;
      }
    }
    .event-right {
      margin-right: 60px;
      .event-year {
        margin-right: 15px;
      }
    }
    .event-detail {
      // width: 160px;
      font-size: 12px;
      padding: 10px 20px 10px 10px;
    }
    .left {
      right: 90px;
    }
    .right {
      left: 90px;
    }
  }
}
</style>
